import styled from 'styled-components';
import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { color, typography, space } from 'styled-system';
import { List } from './containers';



const Hero = styled.h1`
    ${color}
    ${typography}
    font-family: ${props => props.theme.fonts.display};
    margin: 0;
`;

const Title = styled.h1`
    ${color}
    ${typography}
    font-family: ${props => props.theme.fonts.display};
    margin: 0;
`;

const Heading = styled.h2`
    ${color}
    ${typography}
    margin: 0;
`;

const Subheading = styled.h3`
    ${color}
    ${typography}
    font-size: ${props => props.theme.fontSizes[2]}px;
    margin: 0;
    text-transform: uppercase;
`;

const Subheading4 = styled.h4`
    ${color}
    ${typography}
    font-size: ${props => props.theme.fontSizes[2]}px;
    margin: 0;
    text-transform: uppercase;
`

const Paragraph = styled.p`
    ${color}
    ${typography}
    ${space}
`;

const Span = styled.span`
    ${color}
    ${typography}
`

const Label = styled.span`
    ${color}
    ${typography}
    font-size: ${props => props.theme.fontSizes[1]}px;
    margin: 0;
    text-transform: uppercase;
`;

const RichText = ({json}) => {
    let options = {
        renderMark: {
            [MARKS.BOLD]: text => <Span fontWeight="bold">{text}</Span>,
            [MARKS.ITALIC]: text => <Span fontStyle="italic">{text}</Span>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph lineHeight={1.5} mb={3}>{children}</Paragraph>,
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,

            [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri}>{children}</a>,
            [BLOCKS.HEADING_1]: (node, children) => <Title>{children}</Title>,
            [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>,
            [BLOCKS.HEADING_3]: (node, children) => <Subheading>{children}</Subheading>,
            [BLOCKS.HEADING_4]: (node, children) => <Subheading4>{children}</Subheading4>
        },
    }
    return documentToReactComponents(json, options);
}

export {
    Hero,
    Title,
    Heading,
    Subheading,
    RichText,
    Paragraph,
    Label,
}